#{$app}- {
  &projects {
    margin-top: -75px;
    &__ {
      &wrapper{
        background-color: var(--blueA);
        padding-top: 100px;
        padding-bottom: 150px;
        @media(max-width:998px){
          padding-top: 50px;
          padding-bottom: 75px;
        }
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
      }
      &slider {
        border-radius:30px;
        overflow: hidden;
      }
      &image{
        object-fit: cover;
        object-position: contain;
        border-radius:30px;
      }
    }
  }
}
