.menu-anchor:hover {
  background-color: #cafce1 !important;
  font-weight: bolder;
}

.menu-label {
  font-size: 13px;
}
.border-35{
border-radius: 35px;
}
// a,
// .fpTHfu {
//   background-color: #72e9a8 !important;
// }