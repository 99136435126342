#{$app}- {
  &hero {
    background-color: var(--black);
    height: 80vh;
    &__ {
      &title {
        font-size: 80px;
        &- {
          &outlined {
            color: white;
            -webkit-text-stroke-width: 2px;
            -webkit-text-stroke-color: #000000;
            font-weight: 800;
            margin-top: -70px;
            &-- {
              &lg {
                font-size: 220px;
              }
              &md {
                font-size: 180px;
              }
              &sm {
                font-size: 70px;
              }
            }
          }
        }
      }
      &video {
        // height: 350px;
        // width: 350px;
        position: absolute;
        right: 10%;
        top: 15%;
        @media (max-width: 998px) {
          right: 0;
          top: 38%;
        }
      }
    }
  }
}
.video-react-big-play-button {
  height: 50px !important;
  width: 50px !important;
  border-radius: 50% !important;
  }
  
  // to customize the insider triangle button
  .video-react-big-play-button:before {
  font-size: 2rem !important;
  top: 37% !important;
  left: 50% !important;
  transform: translate(-50%, -30%) !important;
  }
  .sid-gradient__text{
    // background: linear-gradient(to right, #050F4E 0%, #7565E0 100%);
    background: linear-gradient(to right, #050F4E, #FBC6FF, #7565E0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }