#{$app}- {
  &UiUxProjects {
    // background-color: #0e0e0e;
    padding: 150px 0;
    @media(max-width:998px){
      padding: 75px 0;
    }
    margin-top: -5%;
    &__ {
      &shape {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        &::before{
          content: "";
          position: absolute;
          background-color: var(--black);
          top: 10%;
          left: 0;
          right: 0;
          bottom: 0;
        }

      }
    }
  }
}
