#{$app}- {
  &services {
    background-color: var(--lightblue);
    padding: 100px 0;
    @media(max-width:998px){
      padding: 50px 0;
    }
    &__ {
      &titleA {
        font-size: 100px;
      }
      &titleB {
        font-size: 250px;
        margin-left: -5%;
        margin-top: -4%;
        opacity: 0.3;
      }
    }
  }
}
