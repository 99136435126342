#{$app}- {
  &popUp {
    &__ {
      &overlay{
        // background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
        z-index: 999; /* Ensure it's above other content */
        backdrop-filter: blur(16px) saturate(180%);
        -webkit-backdrop-filter: blur(16px) saturate(180%);
        background-color: rgba(255, 255, 255, 0);
      }
      &container{
        border-radius: 30px;
        background-color: var(--black);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        max-width:650px;
        width:90%;
      }
      &button{
        height: 40px;
        width:40px;
        top: -20px!important;
        border-radius: 50%;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        border: 1px solid var(--black);
      }
    }
  }
}


.body-no-scroll {
  // overflow: hidden;
}
