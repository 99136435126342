@import "./mixins";
// Spacings
@include Spacings;
// Font Sizes
@include TextSizes;
// Font Weights
@include fontWeights();
@include LineHeights();

.color-white {
  color: var(--white);
}
.color-black {
  color: var(--black);
}
.background-black {
  background-color: var(--black);
}
.color-danger {
  color: var(--red);
}
.cms-app-color {
  color: var(--cms-app-color);
}
.opacity {
  &- {
    &1 {
      opacity: 0.1;
    }
    &2 {
      opacity: 0.2;
    }
    &3 {
      opacity: 0.3;
    }
    &4 {
      opacity: 0.4;
    }
    &5 {
      opacity: 0.5;
    }
    &6 {
      opacity: 0.6;
    }
    &7 {
      opacity: 0.7;
    }
    &8 {
      opacity: 0.8;
    }
    &9 {
      opacity: 0.9;
    }
  }
}

#{$app}-{
  &font {
    &__ {
      &head {
        font-family: var(--heading-font);
      }
      &body {
        font-family: var(--body-font);
      }
      &name {
        font-family: var(--name-font);
      }
    }
  }
}

// section spacings
// #{$app}-{
//   &section{
//     padding: 75px;
//   }
// }
.border-bottom-lightblue{
  border-bottom: 1px solid var(--lightblue);
}
.border-dashed{
  border:1px dashed var(--lightblue);
}
.border-solid{
  border:1px solid var(--lightblue);
}
.hover-unset{
  &:hover{
    all: unset;
  }
}
.dashboard-input{
  border-radius: 14px;
  border: 1px solid var(--cms-app-color);
  width: 100%;
  padding: 0.7rem 0.7rem;
}




// dashboard helpers
.sid-db-bg{
  background-color: #121212;
}