/* header */
.sid-btn__normal {
  font-size: 20px;
  color: var(--black);
  text-decoration: none;
  font-weight: 700;
  -webkit-transition: color 0.5s ease-out;
  -moz-transition: color 0.5s ease-out;
  -o-transition: color 0.5s ease-out;
  transition: color 0.5s ease-out;
}
.sid-btn__normal:hover {
  color: var(--orange);
}

.sid-btn__filled {
  font-size: 20px;
  background-color: var(--black);
  color: var(--white);
  text-decoration: none;
  font-weight: 700;
  padding: 5px 45px;
  border-radius: 10px;
  -webkit-transition: background-color 0.5s ease-out;
  -moz-transition: background-color 0.5s ease-out;
  -o-transition: background-color 0.5s ease-out;
  transition: background-color 0.5s ease-out;
}
.sid-btn__filled:hover {
  background-color: var(--orange);
}
.sid-button__neon{
  background-color: var(--white);
  border-radius: 45px;
  color: var(--black);
  font-size: 25px;
  padding: 15px 35px;
  -webkit-transition: background-color 0.5s ease-out;
  -moz-transition: background-color 0.5s ease-out;
  -o-transition: background-color 0.5s ease-out;
  transition: background-color 0.5s ease-out;
  -webkit-transition: color 0.5s ease-out;
  -moz-transition: color 0.5s ease-out;
  -o-transition: color 0.5s ease-out;
  transition: color 0.5s ease-out;
}
.sid-button__neon:hover{
  background-color:var(--yellow);
  color:var(--white)
}
