#{$app}- {
  &pvHero {
    background-color: var(--white);
    height: 80vh;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    &::before{
      content: "";
      background-color: rgba(0, 0, 0, 0.5);
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
    &__ {
      &container{
        z-index: 2;
      }
    }
  }
}
